 /*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

 .header-container {
     z-index: 1;
 }

 .hero-section {
     height: 100vh;
     display: -ms-grid;
     display: grid;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
 }

 .flow-wrapper-in-header {
     max-width: 36%;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     display: none;
 }

 #flow-hero-logo-header {
     width: 120px;
 }

 #cop26-hero-logo-header {
     font-size: 2.2em;
 }

 #flow-hero-logo {
     width: 100%;
 }

 .flow-wrapper {
     width: 36vw;
     position: absolute;
     left: 10%;
     top: 35%;
     z-index: 3;
     -webkit-transition: 0.8s;
     -o-transition: 0.8s;
     transition: 0.8s;
 }

 .flow-wrapper-slide {
     width: 8vw;
     position: fixed;
     top: 2%;
     left: 4%;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
 }

 #flow-line {
     display: none;
 }

 .hero-elem-wrapper {
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
 }

 .hero-elem-container {
     position: relative;
     height: 100vh;
     width: 100vw;
     overflow: hidden;
 }

 .hero-elem {
     position: absolute;
     -webkit-transition: 1s;
     -o-transition: 1s;
     transition: 1s;
 }

 .hero-elem img {
     width: 100%;
 }

 .hero-cop26 {
     font-size: 5em;
     margin: 0;
 }

canvas  {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1; 
	
}